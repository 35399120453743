.slick-prev:before,
.slick-next:before {
  color: #f7b500;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.slick-track {
  margin: 0;
}
.slick-prev {
  left: -20px !important;
}
.slick-next {
  right: -20px !important;
}
.slick-dots {
  margin-bottom: 5px;
}
.slick-dots li button:before {
  font-size: 10px;
}
